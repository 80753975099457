.chat-messages {
	max-height: 60vh;
	overflow-y: auto;
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	width: 8px;
}
::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
	background-color: #555;
}

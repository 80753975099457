html,
body {
	background-color: #fff !important;
	margin: 0;
	padding: 0;
	height: 100vh;
}

#root {
	height: 100%;
}
